<template>
  <div>
    <svg id="gantt"></svg>
    <ModelEdit
      v-model="showObjectiveEdit"
      type="Objective"
      title="Edit objective"
      v-on:closed="closePopup"
    />
    <!-- v-on:saved="() => getUsers()"
      v-on:alert="(alert) => $emit('alert', alert)" -->
  </div>
</template>
<script>
import Gantt from "frappe-gantt";
import { format } from "date-fns";
import ModelEdit from "../../ModelEdit.vue";

export default {
  name: "GanttChartV2",
  components: {
    ModelEdit,
  },
  props: {
    objectives: Array,
  },
  data() {
    return {
      showObjectiveEdit: false,
      objectiveToEdit: null,
      gantt: null,
      options: {
        bar_corner_radius: 10,
        // arrow_curve: 20,
        bar_height: 30,
        // on_click: (ganttObjective) => {
        //   console.log("click called", ganttObjective);
        //   this.showObjectiveEdit = true;
        //   this.objectiveToEdit = this.parseToModel(ganttObjective);
        // },
        // on_date_change: (ganttObjective, start, end) => {
        // },
        // on_progress_change: (task, progress) => {
        //   console.log(task, progress);
        //   this.closePopup();
        // },
        // on_view_change: (mode) => {
        //   console.log(mode);
        // },
      },
    };
  },
  mounted() {
    const tasks = [
      {
        id: "Task 1",
        name: "Set up account",
        start: "2016-12-28",
        end: "2016-12-29",

      },
      {
        id: "Task 2",
        name: "Redesign website",
        start: "2016-12-29",
        end: "2016-12-30",
        dependencies: 'Task 1'
      },
      {
        id: "Task 3",
        name: "Add payment page",
        start: "2016-12-30",
        end: "2016-12-31",
        dependencies: 'Task 2'
      },
    ];

    /**
     * for development purposes
     * componenet hot-reloading issue
     * when component is first loaded the element
     * with id is replaced completely
     * and no longer has the ID
     * so when hot-realoding, this initialisation fails
     * because the element with id doesnt exist
     */

    const existingGanttChart =
      document.getElementsByClassName("gantt-container")[0];

    this.gantt = new Gantt(existingGanttChart || "#gantt", tasks, this.options);
    // this.gantt.change_view_mode("Week");

    /**
     * this library has a weird way of handling click events
     * it waits 1 second before allowing any more clicks
     * if you move or resize an item, it sets a timeout of 1000 milliseconds
     * before you sets a variable allowing other clicks
     *
     * So a custom move and click events have been implemented
     * to detect if a mouse up is a move or a click
     */
    this.gantt.bars.forEach((bar) => {
      let moved;
      bar.$bar.parentElement.addEventListener("mousedown", () => {
        moved = false;
      });
      bar.$bar.parentElement.addEventListener("mousemove", () => {
        moved = true;
      });

      bar.$bar.parentElement.addEventListener("mouseup", () => {
        if (!moved) {
          this.showObjectiveEdit = !this.showObjectiveEdit;
          if (this.showObjectiveEdit) {
            this.objectiveToEdit = bar.task;
          } else {
            this.objectiveToEdit = null;
          }
        }
      });
    });

    this.gantt;
  },
  methods: {
    parseToGanttTask(objective) {
      return {
        id: objective._id,
        name: objective.name,
        start: format(objective.startDateMilliseconds, "YYYY/MM/dd"),
        end: format(objective.endDateMilliseconds, "YYYY/MM/dd"),
        progress: 100,
      };
    },
    parseToObjective(ganttTask) {
      return {
        _id: ganttTask.id,
        name: ganttTask.name,
        startDateMilliseconds: new Date(ganttTask.start).getTime(),
        endDateMilliseconds: new Date(ganttTask.end).getTime(),
      };
    },
    closePopup() {
      this.gantt.hide_popup();
      this.gantt.unselect_all();
      this.showObjectiveEdit = false;
      this.objectiveToEdit = null;
    },
  },
};
</script>
<style>
.gantt .bar,
.gantt .bar-wrapper.active .bar,
.gantt .bar-wrapper:hover .bar {
  fill: #329482;
}
.popup-wrapper {
  display: none;
}

.gantt .bar-label {
  font-size: 16px;
  font-weight: normal;
}
</style>